/* eslint-disable consistent-return */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
import React, { useState } from 'react';
import { Col, Form, Row, Spinner, InputGroup, Image } from 'react-bootstrap';
import MaskedInput from 'react-text-mask';
import { toast } from 'react-toastify';
import { SINTEGRA_HOMO } from '../../../config/portal';
import customerInputsLength from '../../../constants/customerInputsLength';
import Role from '../../../constants/roles';
import { AuthValues, useAuth } from '../../../contexts/authContext';
import { signUpRoute } from '../../../routes/config';
import IntegrationHttpService from '../../../services/http/integration-http';
import {
    cnpjMask,
    phoneMask,
    sellerLevelMask,
    landlineMask,
} from '../../../utils/masks';
import normalizeString from '../../../utils/strings';
import userHasRoles from '../../../utils/userHasRoles';
import cnpjIcon from '../../../assets/icons/company.svg';
import telephoneIcon from '../../../assets/icons/telephone.svg';
import fancyNameIcon from '../../../assets/icons/fantasy.svg';
import pencilIcon from '../../../assets/icons/pencil.svg';
import hashtagIcon from '../../../assets/icons/hashtag.svg';
import briefcaseIcon from '../../../assets/icons/register_briefcase.svg';
import { StyledP } from './styles';
import sellerStatus from '../../../constants/sellerStatus';
import BrasilService from '../../../services/http/brasil.http';

const IntegratorCompany = (props: any) => {
    const pathname = window.location.pathname;
    const [loadingSintegraData, setLoadingSintegraData] = useState(false);

    const { user }: AuthValues = useAuth();

    const isAdministratorOrSupervisor = userHasRoles(user, [
        Role.Administrator,
        Role.Registration,
        Role.CommercialSupervisor,
    ]);

    const canEditCnpj = () =>
        (props.integrator && props.integrator.status === 'PENDING') ||
        !props.integrator.id;

    const getCitiesByState = async (state: string) => {
        const response = await BrasilService.getCitiesByState(state);

        // eslint-disable-next-line arrow-body-style
        const cities = response.map((obj) => {
            return {
                label: obj.name,
                value: obj.id,
            };
        });

        return cities;
    };

    const handlePhoneMask = (rawValue: any) => {
        const value: string = rawValue.replace(/\D/g, '');

        if (value.length <= 10) {
            return landlineMask;
        }

        return phoneMask;
    };

    const handleCnpjChanges = async (event: any) => {
        const value: any = event?.target?.value?.replace(/\D/g, '');

        props.handleChanges([{ target: { name: 'cnpj', value } }]);

        const changes: any = [];

        if (value.length === 14 && !props.errors.cnpj && !SINTEGRA_HOMO) {
            setLoadingSintegraData(true);

            try {
                const response: any =
                    await IntegrationHttpService.getSintegraData(value);

                if (!response.data.result) {
                    return toast.info(response.data.message);
                }

                const phoneNumber =
                    props.integrator.phone || response?.data?.result?.telefone;

                const cities = await getCitiesByState(
                    response?.data?.result?.estado,
                );
                const city: any = cities.find(
                    (item: any) =>
                        item.label ===
                        normalizeString(
                            response?.data?.result?.municipio ||
                                response?.data?.result?.cidade ||
                                '',
                        ).toUpperCase(),
                );

                changes.push({ target: { name: 'cnpj', value } });
                changes.push({
                    target: {
                        name: 'fancyName',
                        value: response?.data?.result?.nomeFantasia.substring(
                            0,
                            customerInputsLength.fancyName,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'name',
                        value: response?.data?.result?.nome.substring(
                            0,
                            customerInputsLength.name,
                        ),
                    },
                });
                changes.push({ target: { name: 'hasIe', value: '0' } });
                changes.push({ target: { name: 'ie', value: '' } });

                if (response?.data?.result?.inscricaoEstadual) {
                    changes.push({ target: { name: 'hasIe', value: '1' } });
                    changes.push({
                        target: {
                            name: 'ie',
                            value: response?.data?.result?.inscricaoEstadual,
                        },
                    });
                }

                changes.push({
                    target: {
                        name: 'erpState',
                        value: response?.data?.result?.estado,
                    },
                });
                changes.push({
                    target: {
                        name: 'erpCity',
                        value:
                            city.value ||
                            (response?.data?.result?.municipio ??
                                response?.data?.result?.cidade),
                    },
                });
                changes.push({
                    target: {
                        name: 'erpCityName',
                        value:
                            city.label ||
                            (response?.data?.result?.municipio ??
                                response?.data?.result?.cidade),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressCep',
                        value: response?.data?.result?.cep,
                    },
                });
                changes.push({
                    target: {
                        name: 'addressCep',
                        value: response?.data?.result?.cep,
                    },
                });
                changes.push({
                    target: {
                        name: 'addressDescription',
                        value: response?.data?.result?.logradouro.substring(
                            0,
                            customerInputsLength.addressDescription,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressNeighborhood',
                        value: response?.data?.result?.bairro.substring(
                            0,
                            customerInputsLength.addressNeighborhood,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressNumber',
                        value: response?.data?.result?.numero.substring(
                            0,
                            customerInputsLength.addressNumber,
                        ),
                    },
                });
                changes.push({
                    target: {
                        name: 'addressComplement',
                        value: response?.data?.result?.complemento.substring(
                            0,
                            customerInputsLength.addressComplement,
                        ),
                    },
                });
                changes.push({ target: { name: 'phone', value: phoneNumber } });
                changes.push({
                    target: {
                        name: 'email',
                        value:
                            props.integrator.email ||
                            response?.data?.result?.email ||
                            '',
                    },
                });

                props.handleChanges(changes);
            } catch (err) {
                const error = err as any;

                toast.error(
                    error.message ||
                        'Não foi possível consultar o CNPJ. Prossiga com o cadastro manualmente',
                );
            } finally {
                setLoadingSintegraData(false);
            }
        }
    };

    if (pathname === signUpRoute.path) {
        return (
            <>
                {loadingSintegraData && !props.errors.cnpj && (
                    <>
                        <p>
                            <Spinner
                                animation="border"
                                size="sm"
                                className="mr-2"
                            />
                            Buscando informações da empresa...
                        </p>
                    </>
                )}
                <Row>
                    <Col sm="5">
                        <Form.Group controlId="formBasicName">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={cnpjIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'nexen-form-control'
                                            : ''
                                    }
                                    disabled={
                                        props.isDisabled || !canEditCnpj()
                                    }
                                    value={props.integrator.cnpj}
                                    isInvalid={!!props.errors.cnpj}
                                    as={MaskedInput}
                                    mask={cnpjMask}
                                    onChange={handleCnpjChanges}
                                    isValid={
                                        props.integrator.cnpj &&
                                        !props.errors.cnpj &&
                                        props.signUp
                                    }
                                    name="cnpj"
                                    type="text"
                                    placeholder="CNPJ"
                                />
                                {props.errors.cnpj && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.cnpj}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Col>

                    <Col sm="7">
                        <Form.Group controlId="formBasicPhone">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={telephoneIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'nexen-form-control'
                                            : ''
                                    }
                                    disabled={props.isDisabled}
                                    value={props.integrator.phone}
                                    isInvalid={!!props.errors.phone}
                                    as={MaskedInput}
                                    mask={handlePhoneMask}
                                    guide={false}
                                    onChange={props.handleChanges}
                                    isValid={
                                        props.integrator.phone &&
                                        !props.errors.phone &&
                                        props.signUp
                                    }
                                    name="phone"
                                    type="tel"
                                    placeholder="Telefone"
                                />
                                {props.errors.phone && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.phone}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group controlId="formBasicName">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={fancyNameIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            value={props.integrator.fancyName}
                            isInvalid={!!props.errors.fancyName}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.fancyName &&
                                !props.errors.fancyName &&
                                props.signUp
                            }
                            name="fancyName"
                            maxLength={20}
                            type="text"
                            placeholder="Nome Fantasia"
                        />
                        {props.errors.fancyName && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.fancyName}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>

                <Row>
                    <Col sm="5">
                        <Form.Group controlId="formBasicIe">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={pencilIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'form-control nexen-form-control'
                                            : 'form-control'
                                    }
                                    disabled={props.isDisabled}
                                    value={props.integrator.hasIe}
                                    onChange={props.handleChanges}
                                    name="hasIe"
                                    as="select"
                                    custom
                                    isInvalid={!!props.errors.hasIe}
                                    isValid={
                                        props.integrator.hasIe &&
                                        !props.errors.hasIe &&
                                        props.signUp
                                    }
                                >
                                    <option value="" selected disabled hidden>
                                        Inscrição Estadual
                                    </option>
                                    <option value="0">Isento</option>
                                    <option value="1">Não isento</option>
                                </Form.Control>
                                {props.errors.hasIe && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.hasIe}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Col>
                    <Col sm="7">
                        <Form.Group controlId="formBasicIeValue">
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text
                                        style={{
                                            border: '1px solid #707070',
                                            width: 45,
                                        }}
                                    >
                                        <Image src={hashtagIcon} />
                                    </InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                    className={
                                        pathname === signUpRoute.path
                                            ? 'nexen-form-control'
                                            : ''
                                    }
                                    disabled={
                                        props.isDisabled ||
                                        props.integrator.hasIe !== '1'
                                    }
                                    value={props.integrator.ie}
                                    isInvalid={!!props.errors.ie}
                                    onChange={props.handleChanges}
                                    isValid={
                                        props.integrator.hasIe === '1' &&
                                        props.integrator.ie &&
                                        !props.errors.ie &&
                                        props.signUp
                                    }
                                    name="ie"
                                    type="text"
                                    placeholder="Número de inscrição"
                                    maxLength={20}
                                />
                                {props.errors.ie && (
                                    <Form.Control.Feedback type="invalid">
                                        {props.errors.ie}
                                    </Form.Control.Feedback>
                                )}
                            </InputGroup>
                        </Form.Group>
                    </Col>
                </Row>

                <Form.Group controlId="formBasicName">
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text
                                style={{
                                    border: '1px solid #707070',
                                    width: 45,
                                }}
                            >
                                <Image src={briefcaseIcon} />
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={props.isDisabled}
                            value={props.integrator.name}
                            isInvalid={!!props.errors.name}
                            onChange={props.handleChanges}
                            name="name"
                            type="text"
                            maxLength={40}
                            placeholder="Razão Social"
                        />
                        {props.errors.name && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.name}
                            </Form.Control.Feedback>
                        )}
                    </InputGroup>
                </Form.Group>
            </>
        );
    }

    return (
        <>
            {loadingSintegraData && !props.errors.cnpj && (
                <>
                    <p>
                        <Spinner
                            animation="border"
                            size="sm"
                            className="mr-2"
                        />
                        Buscando informações da empresa...
                    </p>
                </>
            )}
            <Form.Group controlId="formBasicCnpj">
                <Form.Label>CNPJ</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled || !canEditCnpj()}
                    value={props.integrator.cnpj}
                    isInvalid={!!props.errors.cnpj}
                    as={MaskedInput}
                    mask={cnpjMask}
                    onChange={handleCnpjChanges}
                    isValid={
                        props.integrator.cnpj &&
                        !props.errors.cnpj &&
                        props.signUp
                    }
                    name="cnpj"
                    type="text"
                    placeholder="Informe o CNPJ"
                />
                {props.errors.cnpj && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.cnpj}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicFancyName">
                <Form.Label>Nome fantasia</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    value={props.integrator.fancyName}
                    isInvalid={!!props.errors.fancyName}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.fancyName &&
                        !props.errors.fancyName &&
                        props.signUp
                    }
                    name="fancyName"
                    maxLength={20}
                    type="text"
                    placeholder="Informe o nome fantasia"
                />
                {props.errors.fancyName && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.fancyName}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Row>
                <Col sm="5">
                    <Form.Group controlId="formBasicIe">
                        <Form.Label>Inscrição estadual</Form.Label>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'form-control nexen-form-control'
                                    : 'form-control'
                            }
                            disabled={props.isDisabled}
                            value={props.integrator.hasIe}
                            onChange={props.handleChanges}
                            name="hasIe"
                            as="select"
                            custom
                        >
                            <option value="0">Isento</option>
                            <option value="1">Não isento</option>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col sm="7">
                    <Form.Group controlId="formBasicIeValue">
                        <Form.Label>&nbsp;</Form.Label>
                        <Form.Control
                            className={
                                pathname === signUpRoute.path
                                    ? 'nexen-form-control'
                                    : ''
                            }
                            disabled={
                                props.isDisabled ||
                                props.integrator.hasIe !== '1'
                            }
                            value={props.integrator.ie}
                            isInvalid={!!props.errors.ie}
                            onChange={props.handleChanges}
                            isValid={
                                props.integrator.hasIe === '1' &&
                                props.integrator.ie &&
                                !props.errors.ie &&
                                props.signUp
                            }
                            name="ie"
                            type="text"
                            placeholder="Número de inscrição"
                            maxLength={20}
                        />
                        {props.errors.ie && (
                            <Form.Control.Feedback type="invalid">
                                {props.errors.ie}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group controlId="formBasicName">
                <Form.Label>Razão social</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    value={props.integrator.name}
                    isInvalid={!!props.errors.name}
                    onChange={props.handleChanges}
                    name="name"
                    type="text"
                    maxLength={40}
                    placeholder="Informe a razão social"
                />
                {props.errors.name && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.name}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            <Form.Group controlId="formBasicPhone">
                <Form.Label>Telefone</Form.Label>
                <Form.Control
                    className={
                        pathname === signUpRoute.path
                            ? 'nexen-form-control'
                            : ''
                    }
                    disabled={props.isDisabled}
                    value={props.integrator.phone}
                    isInvalid={!!props.errors.phone}
                    as={MaskedInput}
                    mask={handlePhoneMask}
                    guide={false}
                    onChange={props.handleChanges}
                    isValid={
                        props.integrator.phone &&
                        !props.errors.phone &&
                        props.signUp
                    }
                    name="phone"
                    type="tel"
                    placeholder="Informe o telefone"
                />
                {props.errors.phone && (
                    <Form.Control.Feedback type="invalid">
                        {props.errors.phone}
                    </Form.Control.Feedback>
                )}
            </Form.Group>

            {!(pathname === signUpRoute.path) &&
                isAdministratorOrSupervisor &&
                user &&
                !props.noShowLevel && (
                    <Form.Group controlId="formBasicLevel">
                        <Form.Label className="d-block">Nível</Form.Label>
                        <Form.Control
                            value={props.integrator.level}
                            onChange={props.handleChanges}
                            name="level"
                            type="text"
                            as={MaskedInput}
                            mask={sellerLevelMask}
                            placeholder="Nível do integrador"
                        />
                    </Form.Group>
                )}

            {props.integrator.status === sellerStatus.Approved && (
                <Form.Group controlId="formBasicTT">
                    <Form.Check
                        id="switchTT"
                        name="triangulation"
                        type="switch"
                        checked={props.integrator.canDoTriangulation || false}
                        className="float-left"
                        onChange={props.handleChanges}
                        disabled={!isAdministratorOrSupervisor}
                    />

                    <StyledP className="float-left">
                        Podem ser realizadas operações de venda por conta e
                        ordem para este integrador
                    </StyledP>
                </Form.Group>
            )}
        </>
    );
};

export default IntegratorCompany;
