import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import { theme } from '../../styles/react-select-config';

interface Props<T> {
    searchData?: string;
    isDisabled?: boolean;
    value?: T;
    defaultOptions?: T[];
    keyValue: string;
    keyLabel: string;
    className?: string;
    label: string;
    isSearchable: boolean;
    styles?: any;
    ref?: any;
    name?: string;
    loadOptionsData: (page: number, search?: string) => Promise<T[]>;
    setData: (data: T) => void;
}

export default function SelectAsyncPaginate<T>({
    loadOptionsData,
    searchData,
    isDisabled,
    keyValue,
    keyLabel,
    setData,
    label,
    isSearchable,
    className,
    styles,
    ref,
    name,
    value,
    defaultOptions,
}: Props<T>) {
    const [searchStored, setSearchStored] = useState(searchData);

    useEffect(() => {
        setSearchStored(searchData);
    }, [searchData]);

    const loadOptions = async (
        searchQuery: string,
        load: any,
        { page }: { page: number },
    ) => {
        const data = await loadOptionsData(
            page,
            // eslint-disable-next-line no-nested-ternary
            searchQuery && searchQuery.length
                ? searchQuery
                : page === 1
                ? searchStored
                : undefined,
        );
        return {
            options: data,
            hasMore: data.length > 1,
            additional: {
                page: searchQuery ? 2 : page + 1,
            },
        };
    };

    const onChange = (option: any) => {
        setData(option);
        setSearchStored(option);
    };

    return (
        <AsyncPaginate
            key={keyValue}
            isDisabled={isDisabled}
            value={value}
            styles={styles}
            className={className}
            defaultOptions={defaultOptions}
            ref={ref}
            name={name}
            loadOptions={loadOptions}
            getOptionValue={(option: T) => (option as any)[keyValue]}
            getOptionLabel={(option: T) => (option as any)[keyLabel]}
            onChange={onChange}
            theme={theme}
            isSearchable={isSearchable ?? false}
            placeholder={label}
            additional={{
                page: 1,
            }}
        />
    );
}
