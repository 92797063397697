import { LOGISTICS_URL, LIBRARIES_URL, PORTAL_URL } from '../config/portal';
import Role from './roles';

const pages = [
    {
        name: 'Portal',
        icon: 'fas fa-solar-panel',
        piIcon: 'FaSolarPanel',
        url: PORTAL_URL,
        roles: [
            Role.Administrator,
            Role.PointsAdministrator,
            Role.Integrator,
            Role.Commercial,
            Role.CommercialSupervisor,
            Role.Financial,
            Role.TechnicalSupport,
            Role.Logistics,
            Role.CustomerSuccess,
            Role.ShippingCompany,
            Role.Manager,
            Role.Contributor,
            Role.Registration,
            Role.Production,
        ],
    },
    // {
    //     name: 'Logística & Produção',
    //     icon: 'fas fa-truck',
    //     piIcon: 'PiTruck',
    //     url: `${LOGISTICS_URL}/dashboard`,
    //     roles: [
    //         Role.Administrator,
    //         Role.Logistics,
    //         Role.Commercial,
    //         Role.CommercialSupervisor,
    //         Role.CustomerSuccess,
    //         Role.Production,
    //     ],
    // },
    {
        name: 'Biblioteca',
        icon: 'fas fa-book',
        piIcon: 'PiBooks',
        url: LIBRARIES_URL,
        roles: [],
    },
];

export default pages;
