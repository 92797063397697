/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import { toast } from 'react-toastify';
import closeIcon from '../../../assets/icons/close.svg';
import undoIcon from '../../../assets/icons/undo.svg';
import { documentTypes } from '../../../constants/documents';
import {
    SelectStyles,
    SelectTheme,
    StyledDropzoneDiv,
    StyledDropzoneTitle,
    StyledFileDiv,
    StyledH5,
    StyledImage,
    StyledImageDiv,
    StyledLink,
    StyledLinkDiv,
    StyledSelectDiv,
    StyledUl,
} from './styles';
import IntegratorHttpService from '../../../services/http/integrator-http';

export interface Attachment {
    id?: number;
    name: string;
    awsLink?: string;
    type: documentTypes | null;
    extension: string;
    file?: string;
    delete?: boolean;
}

interface Props {
    integratorDocuments: Attachment[];
    setIntegratorDocuments: React.Dispatch<React.SetStateAction<Attachment[]>>;
}

const maxSize = 5242880;
const maxFiles = 10;

const types = [
    {
        value: documentTypes.SocialContract,
        label: 'Contrato Social',
    },
    {
        value: documentTypes.CpfRg,
        label: 'CPF/RG',
    },
    {
        value: documentTypes.AddressReceipt,
        label: 'Comprovante de Endereço',
    },
];

const IntegratorDocuments = (props: Props) => {
    const handleRemove = (file: Attachment, undo?: boolean) => {
        if (file.id) {
            const index = props.integratorDocuments.indexOf(file);

            props.integratorDocuments[index].delete = !undo;

            props.setIntegratorDocuments(
                (previousAttachments: Attachment[]) => [...previousAttachments],
            );
        } else {
            props.setIntegratorDocuments(
                props.integratorDocuments.filter(
                    (attachment: Attachment) => attachment !== file,
                ),
            );
        }
    };

    function arrayBufferToBase64(buffer: any) {
        const bytes = new Uint8Array(buffer);
        let binary = '';

        bytes.forEach((byte) => {
            binary += String.fromCharCode(byte);
        });

        return window.btoa(binary);
    }

    const updateAttachments = (files: File[]) => {
        let attachment: Attachment;

        try {
            files.forEach((file: any) => {
                const reader = new FileReader();

                reader.readAsArrayBuffer(file);

                reader.onload = () => {
                    const buffer = reader.result;

                    attachment = {
                        name: file.name,
                        type: null,
                        file: arrayBufferToBase64(buffer),
                        extension: file.name
                            .slice(file.name.lastIndexOf('.'))
                            .toLowerCase(),
                    };

                    props.setIntegratorDocuments(
                        (previousAttachments: Attachment[]) => [
                            ...previousAttachments,
                            attachment,
                        ],
                    );
                };
            });
        } catch (error) {
            console.log(error);
            toast.error('Erro ao carregar arquivo');
        }
    };

    const handleChange = (file: Attachment, e: any) => {
        const index = props.integratorDocuments.indexOf(file);

        props.integratorDocuments[index].type = e.value;

        props.setIntegratorDocuments((previousAttachments: Attachment[]) => [
            ...previousAttachments,
        ]);
    };

    const handleClick = (file: Attachment) => {
        let contentType = null;

        if (file.extension === '.pdf') {
            contentType = 'application/pdf';
        } else if (file.extension === '.png') {
            contentType = 'image/png';
        } else if (file.extension === '.jpeg' || file.extension === '.jpg') {
            contentType = 'image/jpeg';
        }

        if (!contentType) {
            return;
        }

        const fileSource = `data:${contentType};base64,${file.file}`;
        const downloadLink = document.createElement('a');

        downloadLink.href = fileSource;
        downloadLink.download = file.name;
        downloadLink.click();
    };

    return (
        <>
            <Row className="align-items-start mt-2">
                <Col>
                    <StyledH5>
                        Para análise do cadastro, é necessário o envio da
                        seguinte documentação:
                    </StyledH5>

                    <StyledUl>
                        <li>Contrato social da empresa</li>
                        <li>CPF e RG do administrador da empresa</li>
                        <li>Comprovante de endereço da empresa</li>
                    </StyledUl>
                </Col>
            </Row>
            <Row className="align-items-start mt-2">
                <Col>
                    <Dropzone
                        accept={['image/png', 'image/jpeg', '.pdf']}
                        onDropRejected={() => {
                            toast.error(
                                'Arquivo muito grande ou formato incorreto',
                            );
                        }}
                        multiple
                        maxFiles={maxFiles}
                        maxSize={maxSize}
                        onDrop={(files) => {
                            updateAttachments(files);
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className="custom-file-upload">
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <Row className="align-items-center">
                                        <Col>
                                            <StyledDropzoneDiv>
                                                <StyledDropzoneTitle className="dropzone-title p-4 mt-2">
                                                    Clique ou arraste para
                                                    enviar arquivos
                                                </StyledDropzoneTitle>
                                            </StyledDropzoneDiv>
                                        </Col>
                                    </Row>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </Col>
            </Row>

            <Row className="align-items-start mt-2">
                <Col>
                    {props.integratorDocuments
                        .slice(0)
                        .reverse()
                        .map((file: Attachment, index: number) => (
                            <StyledFileDiv
                                key={index}
                                background={
                                    !file.delete ? '#f0f2f4' : '#fff1f2'
                                }
                                border={!file.delete ? '#ced4da' : '#ff4554'}
                            >
                                <StyledSelectDiv>
                                    <Select
                                        onChange={(e: any) =>
                                            handleChange(file, e)
                                        }
                                        styles={SelectStyles}
                                        value={
                                            file.type === null
                                                ? null
                                                : {
                                                      value: file.type,
                                                      label:
                                                          file.type ===
                                                          documentTypes.SocialContract
                                                              ? 'Contrato Social'
                                                              : file.type ===
                                                                documentTypes.CpfRg
                                                              ? 'CPF/RG'
                                                              : file.type ===
                                                                documentTypes.AddressReceipt
                                                              ? 'Comprovante de Endereço'
                                                              : 'Outro',
                                                  }
                                        }
                                        options={types}
                                        isMulti={false}
                                        theme={SelectTheme}
                                        placeholder="Selecione o tipo do documento..."
                                        isDisabled={!!file.delete}
                                    />
                                </StyledSelectDiv>

                                <StyledLinkDiv>
                                    {file.awsLink ? (
                                        <StyledLink
                                            href={`${IntegratorHttpService.downloadDocumentLink(
                                                `${file.type}`,
                                                `${file.awsLink}${file.extension}`,
                                            )}`}
                                            target="_blank"
                                            rel="noopener noreferrer nofollow external"
                                        >
                                            {file.name.toUpperCase()}
                                        </StyledLink>
                                    ) : (
                                        <StyledLink
                                            newFile
                                            href="#"
                                            onClick={() => handleClick(file)}
                                        >
                                            {file.name
                                                .slice(
                                                    0,
                                                    -file.extension.length,
                                                )
                                                .toUpperCase()}
                                        </StyledLink>
                                    )}
                                </StyledLinkDiv>

                                <StyledImageDiv>
                                    {file.delete ? (
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="tooltip-bottom">
                                                    Desfazer
                                                </Tooltip>
                                            }
                                        >
                                            <StyledImage
                                                onClick={() =>
                                                    handleRemove(file, true)
                                                }
                                                className="float-right"
                                                src={undoIcon}
                                            />
                                        </OverlayTrigger>
                                    ) : (
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="bottom"
                                            overlay={
                                                <Tooltip id="tooltip-bottom">
                                                    Remover
                                                </Tooltip>
                                            }
                                        >
                                            <StyledImage
                                                onClick={() =>
                                                    handleRemove(file)
                                                }
                                                className="float-right"
                                                src={closeIcon}
                                            />
                                        </OverlayTrigger>
                                    )}
                                </StyledImageDiv>
                            </StyledFileDiv>
                        ))}
                </Col>
            </Row>
        </>
    );
};

export default IntegratorDocuments;
