import RequestV2 from './request-v2';

export default class RegionHttpService {
    private static uriV2 = 'region';

    public static readMany(params: { term?: string; all: boolean }) {
        return RequestV2.get(this.uriV2, params);
    }

    public static readOne(id: string) {
        return RequestV2.get(`${this.uriV2}/${id}`);
    }

    public static create(data: { name: string; states: string }) {
        return RequestV2.post(this.uriV2, data);
    }

    public static update(id: string, data: { name: string; states: string }) {
        return RequestV2.put(`${this.uriV2}/${id}`, data);
    }

    public static async delete(id: number) {
        return RequestV2.del(`${this.uriV2}/${id}`);
    }
}
