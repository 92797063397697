import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal, Nav, Spinner } from 'react-bootstrap';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { validate } from 'email-validator';
import { conformToMask } from 'react-text-mask';
import CustomerUser from '../../components/Customer/CustomerUser';
import CustomerAddress from '../../components/Customer/CustomerAddress';
import CustomerHttpService from '../../services/http/customer-http';
import {
    customerUserRules,
    customerAddressRules,
} from '../../validations/customer';
import getValidationsErrors from '../../utils/getValidationsErrors';
import { StyledNav } from '../../styles/nav';
import userHasRoles from '../../utils/userHasRoles';
import Role from '../../constants/roles';
import { AuthValues, useAuth } from '../../contexts/authContext';
import { StyledConfirmButton, StyledOutlineButton } from '../../styles/button';
import handleResponseError from '../../utils/handleResponseError';
import { cnpjMask, cpfMask } from '../../utils/masks';

interface Props {
    id: string;
    show: boolean;
    onHide: () => void;
    handleUpdate: () => void;
}

const New = (props: Props) => {
    const { user }: AuthValues = useAuth();
    const isIntegrator = userHasRoles(user, [Role.Integrator]);
    const isContributorOrManager = userHasRoles(user, [
        Role.Contributor,
        Role.Manager,
    ]);

    const { id, show, onHide, handleUpdate } = props;
    const [loadIntegrator, setLoadIntegrator] = useState<any | undefined>(
        undefined,
    );
    const [loadIntegratorOptions, setLoadIntegratorOptions] = useState<any[]>(
        [],
    );

    const newCustomer = () => ({
        id: '',
        documentType: 'J',
        document: '',
        name: '',
        fancyName: '',
        email: '',
        phone: '',
        ie: '',
        hasIe: true,
        phoneCode: '',
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        addressComplement: '',
        erpState: '',
        erpCity: '',
        erpCityName: '',
        sellerCode: '',
    });
    const [tab, setTab] = useState('tab-1');
    const formRef: any = useRef<FormHandles>(null);
    const [errors, setErrors] = useState(newCustomer());
    const [documentType, setDocumentType] = useState('J');
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(newCustomer());

    useEffect(() => {
        if (show) {
            return;
        }

        setErrors(newCustomer());

        if (customer.id) {
            setCustomer(customer);
        } else {
            setCustomer({
                ...newCustomer(),
                ...{ documentType: customer.documentType },
            });
        }

        setTab('tab-1');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    useEffect(() => {
        async function loadData(): Promise<void> {
            if (id !== '') {
                setLoading(true);
                try {
                    const response = await CustomerHttpService.readOne(id);
                    const { data } = response;
                    const type =
                        (data.document as string).length === 11 ? 'F' : 'J';
                    setDocumentType(type);
                    setCustomer({
                        id: data.id,
                        documentType: type,
                        document: conformToMask(
                            data.document,
                            type === 'J' ? cnpjMask : cpfMask,
                        ).conformedValue,
                        name: data.name,
                        fancyName: data.fancyName,
                        email: data.email,
                        phone: `(${data.phoneCode})${data.phone}`,
                        ie: data.ie,
                        hasIe: data.hasIe,
                        phoneCode: data.phoneCode,
                        addressCep: data.addressCep,
                        addressNumber: data.addressNumber,
                        addressDescription: data.addressDescription,
                        addressNeighborhood: data.addressNeighborhood,
                        addressComplement: data.addressComplement,
                        erpCity: data.erpCity,
                        erpCityName: data.erpCityName,
                        erpState: {
                            label: '',
                            value: data.erpState,
                        } as any,
                        sellerCode: data.sellerErpCode,
                    });
                } catch (error) {
                    console.log(error);
                    throw error;
                } finally {
                    setLoading(false);
                }
            } else {
                setCustomer(newCustomer());
                setDocumentType('J');
            }
        }

        loadData();
    }, [id]);

    useEffect(() => {
        if (customer.documentType === 'F' && !customer.id) {
            setCustomer({ ...customer, ...{ ie: '', hasIe: false } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customer.documentType]);

    useEffect(() => {
        console.log(documentType);
    }, [documentType]);

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            if (item.target.name === 'erpState') {
                changes.erpState = customer.erpState || item.target.value.code;
                changes.erpCity =
                    customer.erpCityName && customer.erpCity
                        ? {
                              value: customer.erpCity,
                              label: customer.erpCityName,
                          }
                        : '';
            }

            if (item.target.name === 'hasIe' && item.target.value === false) {
                changes.ie = 'ISENTO';
            }

            if (item.target.name === 'hasIe' && item.target.value === true) {
                changes.ie = '';
            }

            if (item.target.name === 'hasIe') {
                changes.hasIe = !customer.hasIe;

                return;
            }

            if (item.target.name === 'sellerCode') {
                changes.sellerCode = item.target.value.value;
            }

            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });

        setCustomer({ ...customer, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const prepareData = (data: any) => {
        let sellerCode: any =
            typeof data.sellerCode === 'object'
                ? data.sellerCode.value
                : data.sellerCode;

        if (isIntegrator) {
            sellerCode = user.seller.id;
        }

        if (isContributorOrManager) {
            sellerCode = user.owner?.id;
        }

        const customerData = {
            name: data.name
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            phone: data.phone.replace(/\D/g, '').substring(2),
            ie: data.hasIe === true ? data.ie : 'ISENTO',
            erpCity:
                typeof data.erpCity === 'object'
                    ? data.erpCity.value
                    : data.erpCity,
            erpCityName:
                typeof data.erpCity === 'object'
                    ? data.erpCity.label
                    : data.erpCityName,
            erpState:
                typeof data.erpState === 'object'
                    ? data.erpState.value
                    : data.erpState,
            addressCep: data.addressCep.toString().replace(/\D/g, ''),
            addressNumber: data.addressNumber
                .toString()
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressDescription: data.addressDescription
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressNeighborhood: data.addressNeighborhood
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressComplement: (data.addressComplement ?? '')
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            fancyName:
                data.documentType === 'J'
                    ? data.fancyName
                          .normalize('NFD')
                          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
                    : data.name
                          .normalize('NFD')
                          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            documentType: data.documentType,
            document: data.document
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            email: data.email,
            phoneCode: `0${data.phone.match(/\d+/)[0]}`,
            sellerCode,
        };

        return customerData;
    };

    const save = async () => {
        if (isIntegrator || isContributorOrManager) {
            customer.sellerCode = '0';
        }

        try {
            const schema = Yup.object().shape(customerUserRules);

            await schema.validate(customer, {
                abortEarly: false,
                context: customer,
            });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);

            setTab('tab-1');
            return;
        }

        try {
            const schema = Yup.object().shape(customerAddressRules);

            await schema.validate(customer, { abortEarly: false });
        } catch (error) {
            const err = error as any;

            setErrors(getValidationsErrors(err) as any);

            setTab('tab-2');
            return;
        }

        const data = prepareData(customer);

        try {
            let response = null;

            if (customer.id) {
                response = await CustomerHttpService.update(customer.id, data);
            } else {
                response = await CustomerHttpService.create(data);
            }

            if (response) {
                toast.success('Cliente salvo com sucesso!');
                onHide();
                handleUpdate();
            }
        } catch (error) {
            handleResponseError(error, 'Erro ao salvar cadastro do cliente');
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoading(true);

        await save();

        setLoading(false);
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                setLoadIntegrator(undefined);
                setLoadIntegratorOptions([]);
                onHide();
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {id ? customer.name : 'Adicionar um novo cliente'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Check
                            disabled={!!id}
                            checked={documentType === 'J'}
                            type="radio"
                            name="documentType"
                            value="J"
                            onClick={() => {
                                setDocumentType('J');
                            }}
                            onChange={handleChanges}
                            label="Meu cliente é pessoa juridica"
                        />
                        <Form.Check
                            disabled={!!id}
                            checked={documentType === 'F'}
                            type="radio"
                            name="documentType"
                            value="F"
                            onClick={() => {
                                setDocumentType('F');
                            }}
                            onChange={handleChanges}
                            label="Meu cliente é pessoa fisica"
                        />
                        <Form.Row
                            style={{ marginRight: '-15px !important' }}
                            className="mt-3 pr-1 pl-1"
                        >
                            <StyledNav
                                variant="tabs"
                                activeKey={tab}
                                onSelect={(selectedKey: any) =>
                                    setTab(selectedKey)
                                }
                            >
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="tab-1"
                                        disabled={loading}
                                    >
                                        Dados Gerais
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link
                                        eventKey="tab-2"
                                        disabled={loading}
                                    >
                                        Endereço
                                    </Nav.Link>
                                </Nav.Item>
                            </StyledNav>
                        </Form.Row>
                    </Form.Group>
                    {tab === 'tab-1' ? (
                        <CustomerUser
                            errors={errors}
                            customer={customer}
                            documentType={documentType}
                            handleChanges={handleChanges}
                            setLoadIntegrator={setLoadIntegrator}
                            loadIntegrator={loadIntegrator}
                            setLoadIntegratorOptions={setLoadIntegratorOptions}
                            loadIntegratorOptions={loadIntegratorOptions}
                        />
                    ) : (
                        <CustomerAddress
                            errors={errors}
                            customer={customer}
                            handleChanges={handleChanges}
                        />
                    )}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <StyledOutlineButton
                    variant="outline-primary"
                    onClick={() => {
                        onHide();
                    }}
                    disabled={false}
                >
                    Cancelar
                </StyledOutlineButton>
                <StyledConfirmButton onClick={handleSubmit} disabled={loading}>
                    {loading ? <Spinner animation="border" /> : 'Salvar'}
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
};

// eslint-disable-next-line import/prefer-default-export
export { New };
