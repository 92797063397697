/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect } from 'react';
import { Col, Modal, Row, Form, Button, Spinner } from 'react-bootstrap';
import { Controller, FieldError, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { useQuery } from 'react-query';
import * as Yup from 'yup';
import { StyledRow, StyledTitle } from './styles';
import {
    optionsTheme,
    theme,
    tradeControl,
} from '../../../../../styles/react-select-config';
import Branch from '../../../../../shared/interfaces/branch.interface';
import BranchHttpService from '../../../../../services/http/branch-http';
import ProductHttpService from '../../../../../services/http/product.http';

interface FormData {
    name: string;
    branches: BranchOptions[];
}

interface BranchOptions {
    label: string;
    value: number;
    id: number;
}

const InverterBrandModal = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [isDisabled, setDisabled] = useState(true);
    const {
        register,
        watch,
        setValue,
        reset,
        control,
        errors,
        setError,
        clearErrors,
    } = useForm<FormData>({
        defaultValues: {
            name: '',
            branches: [],
        },
    });

    const formData = watch();

    const inverterBrandRules = {
        branches: Yup.string().required('Campo obrigatório'),
    };

    async function saveInverterBrand() {
        setLoading(true);

        clearErrors();

        try {
            const schemaInverterBrand = Yup.object().shape(inverterBrandRules);

            await schemaInverterBrand.validate(formData, {
                abortEarly: false,
            });
        } catch (error) {
            setError('branches', {
                type: 'campo',
                message: 'Campo Obrigatório',
            });
            setLoading(false);

            return;
        }

        try {
            const inverterBrand: any = {
                id: props?.selectedInverterBrand?.id,
                name: formData?.name,
                active: props?.selectedInverterBrand?.active
                    ? props?.selectedInverterBrand?.active
                    : false,
                branchIds: formData.branches.map((branch) => branch.id),
            };

            const response = await ProductHttpService.saveInverterBrand(
                inverterBrand,
            );

            if (response.status === 201) {
                toast.success('Marca de inversor salva.');
                props.refetch();
            }
        } catch (error) {
            toast.error('Erro ao salvar marca de inversor.');
        } finally {
            setLoading(false);
        }

        props.onHide();
    }

    useEffect(() => {
        if (formData?.name?.length > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [formData]);

    useEffect(() => {
        const selectedBranches = props?.selectedInverterBrand?.branches?.map(
            (item: Branch) => ({
                label: item.name,
                value: item.id,
                id: item.id,
            }),
        );

        if (props?.selectedInverterBrand?.name) {
            setValue('name', props?.selectedInverterBrand?.name);
            setValue('branches', selectedBranches);
        } else {
            reset({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.selectedInverterBrand?.name]);

    async function loadCompanyBranches() {
        const response = await BranchHttpService.readMany({});

        const companyBranches: BranchOptions[] = [];

        if (response?.data?.data) {
            response.data.data.map((branch: Branch) =>
                companyBranches.push({
                    label: branch.name,
                    id: branch.id,
                    value: branch.id,
                }),
            );
        }

        return companyBranches;
    }

    const checkForBranch = (id: number) => {
        const currentBranches = formData?.branches?.map(
            (branch: BranchOptions) => branch.id,
        );

        return !currentBranches?.includes(id);
    };

    const { data: companyBranches, isLoading } = useQuery(
        'companyBranches',
        loadCompanyBranches,
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
        },
    );

    return (
        <>
            <Modal
                className="modal-dialog-scrollable"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                size="md"
                centered
            >
                <Modal.Header>
                    <Modal.Title
                        className="ml-3 mr-3"
                        id="contained-modal-title-vcenter"
                    >
                        Marca Inversor
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="w-100">
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Filiais</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Group controlId="formBasicBranches">
                                <Controller
                                    className="form-control-nexen"
                                    as={Select}
                                    control={control}
                                    name="branches"
                                    options={
                                        !isLoading &&
                                        companyBranches?.filter(
                                            (branch: BranchOptions) =>
                                                checkForBranch(branch.id),
                                        )
                                    }
                                    isMulti
                                    styles={{
                                        control: tradeControl,
                                        option: optionsTheme,
                                    }}
                                    theme={theme}
                                    placeholder="Selecione..."
                                    defaultValue={[]}
                                    noOptionsMessage={() => 'Sem opções'}
                                />
                                {errors.branches && (
                                    <Form.Control.Feedback
                                        type="invalid"
                                        className="d-block"
                                    >
                                        {
                                            (
                                                errors?.branches as unknown as FieldError
                                            )?.message
                                        }
                                    </Form.Control.Feedback>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <StyledRow className="ml-0 mt-2 mr-1">
                        <Col>
                            <StyledTitle>Nome</StyledTitle>
                        </Col>
                    </StyledRow>
                    <Row className="ml-0 mt-2 mr-1">
                        <Col>
                            <Form.Control
                                ref={register}
                                name="name"
                                type="string"
                                className="form-control-nexen"
                                placeholder="Digite aqui..."
                            />
                        </Col>
                    </Row>

                    <StyledRow className="ml-0 mt-5 mr-1">
                        <Col sm={6} />
                        <Col>
                            <Button
                                className="w-100 float-right"
                                variant="outline-primary"
                                onClick={() => {
                                    props.onHide();
                                }}
                            >
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                className="w-100 float-right"
                                onClick={() => {
                                    saveInverterBrand();
                                }}
                                disabled={isDisabled}
                            >
                                Salvar{'   '}
                                {loading && (
                                    <Spinner animation="border" size="sm" />
                                )}
                            </Button>
                        </Col>
                    </StyledRow>
                </Modal.Body>
                <Modal.Footer className="ml-3 mr-3 mb-2" />
            </Modal>
        </>
    );
};

export default InverterBrandModal;
