import RequestV2 from './request-v2';

export interface ListInvertersParams {
    skip?: number;
    take?: number;
    term?: string;
    brandFilter?: string;
    active?: string;
}

export default class KitHttpService {
    private static uri = 'kit';

    public static getKits(data: any) {
        return RequestV2.get(`${this.uri}`, data);
    }

    public static async saveKits(item: FormData) {
        return RequestV2.post(`${this.uri}`, item, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static async deleteKits() {
        return RequestV2.del(`${this.uri}`);
    }
}
