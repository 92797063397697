enum customerInputsLength {
    name = 100,
    fancyName = 100,
    addressNumber = 5,
    addressComplement = 20,
    addressDescription = 40,
    addressNeighborhood = 20,
}

export default customerInputsLength;
