export enum documentTypes {
    TermsAndConditions = 'TERMS_AND_CONDITIONS',
    CpfRg = 'CPF_RG',
    SocialContract = 'SOCIAL_CONTRACT',
    AddressReceipt = 'ADDRESS_RECEIPT',
}

export enum documentFolders {
    SellerDocuments = 'seller_documents/',
}

export enum documentSubFolders {
    CpfRg = 'cpf_rg/',
    SocialContrats = 'social_contracts/',
    AddressReceipts = 'address_receipts/',
}

export enum documentTypesTranslate {
    TERMS_AND_CONDITIONS = 'Contrato Digital',
    CPF_RG = 'CPF/RG',
    SOCIAL_CONTRACT = 'Contrato Social',
    ADDRESS_RECEIPT = 'Comprovante de Endereço',
}
