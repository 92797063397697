import { useRef, useState } from 'react';
import {
    Button,
    ButtonGroup,
    Col,
    Dropdown,
    Form,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import Highlighter from 'react-highlight-words';
import { toast } from 'react-toastify';
import { FiFilter } from 'react-icons/fi';
import { FaEraser } from 'react-icons/fa6';
import { useForm } from 'react-hook-form';
import { useInfiniteQuery, useMutation } from 'react-query';
import { ReactComponent as ReactMoreIcon } from '../../../../assets/icons/more.svg';
import NotificationIcon from '../../../../constants/notificationIcon';
import { ListInvertersParams } from '../../../../services/http/kit-v2.http';
import Branch from '../../../../shared/interfaces/branch.interface';
import { StyledPageTitle } from '../../../../styles/pageTitle';
import ConfirmationDialog from '../../../../utils/ConfirmationDialog';
import { StyledTh } from '../styles';
import InverterModal from './InvertersModal';
import Inverter from '../../../../shared/interfaces/inverter.interface';
import { SelectOption } from '../../../Select';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import InvertersFilterSection from './FilterSection';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import ProductHttpService from '../../../../services/http/product.http';

export interface InverterFilterProps {
    filterTerm: string;
    filterBrand: Array<SelectOption<string>>;
    filterActive: SelectOption<string>;
}

export default function InvertersTable() {
    const rowsPerPage = 20;
    const [showFilterSection, setShowFilterSection] = useState(false);

    const [showAddInverterModal, setShowAddInverterModal] =
        useState<boolean>(false);
    const [showRemoveInverterModal, setShowRemoveInverterModal] =
        useState<boolean>(false);
    const [selectedInverter, setSelectedInverter] = useState<Inverter | null>(
        null,
    );

    const form = useForm<InverterFilterProps>({
        shouldUnregister: false,
        defaultValues: {
            filterTerm: '',
            filterBrand: [],
            filterActive: undefined,
        },
    });

    const formData = form.watch();
    const termTrimmed = formData.filterTerm.trim();

    const invertersQuery = useInfiniteQuery({
        queryKey: [
            'inverters',
            termTrimmed,
            formData.filterBrand,
            formData.filterActive,
        ],
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        queryFn: async ({ pageParam = 0 }) => {
            const params: ListInvertersParams = {
                skip: pageParam,
                take: rowsPerPage,
                term: termTrimmed || '',
                brandFilter:
                    formData.filterBrand
                        ?.map((item: SelectOption) => item.value)
                        .join(',') || '',
                active: formData.filterActive?.value ?? '',
            };

            const response = await ProductHttpService.getInverters(params);

            return {
                data: response.data.data,
                currentPage: pageParam,
                pages: Math.ceil(response.data.total / rowsPerPage),
            };
        },
        getNextPageParam: ({ currentPage, pages }) =>
            currentPage + 1 < pages ? currentPage + 1 : null,
        onError: (err) => {
            const error = err as any;

            if (isFriendlyHttpError(error)) {
                toast.error(error.message);
            }

            toast.error('Ocorreu um erro ao buscar inversores');
        },
    });

    const target = useRef(null);

    useIntersectionObserver({
        target,
        onIntersect: invertersQuery.fetchNextPage,
        enabled: invertersQuery.hasNextPage,
    });

    const showLoading =
        invertersQuery.isLoading ||
        invertersQuery.isFetchingNextPage ||
        !invertersQuery.isFetched;

    const handleActiveMutation = useMutation(
        async (inverter: Inverter) => {
            const response = await ProductHttpService.saveInverter({
                ...inverter,
                active: !inverter.active,
            });
            return response.data;
        },
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Erro ao salvar inversor.');
            },
            onSuccess: async (inverter: Inverter) => {
                if (inverter.active) {
                    toast.success('Inversor ativado.');
                } else {
                    toast.success('Inversor desativado.');
                }

                invertersQuery.refetch();
            },
        },
    );

    const deleteMutation = useMutation(
        async () => {
            if (!selectedInverter?.id) {
                throw new Error('Nenhum inversor selecionado para exclusão');
            }
            const response = await ProductHttpService.deleteInverter(
                selectedInverter?.id,
            );
            return response.data;
        },
        {
            onError: (error: any) => {
                if (isFriendlyHttpError(error)) {
                    toast.error(error.message);

                    return;
                }

                toast.error('Ocorreu um erro ao remover o inversor');
            },
            onSuccess: async () => {
                toast.success('Inversor removido com sucesso');

                invertersQuery.refetch();
            },
        },
    );

    const inverters = invertersQuery.data?.pages.reduce(
        (accumulator, current) => accumulator.concat(current.data),
        [] as Inverter[],
    );

    return (
        <>
            <Row>
                <Col>
                    <StyledPageTitle className="mt-2">
                        Inversores
                    </StyledPageTitle>
                </Col>
                <ButtonGroup>
                    {form.formState.isDirty && (
                        <Button
                            style={{
                                color: '#2F80ED',
                            }}
                            className="m-2"
                            variant="text"
                            onClick={() => form.reset()}
                        >
                            <FaEraser
                                fill="#2F80ED"
                                className="mr-2"
                                style={{
                                    color: '#2F80ED',
                                    width: '21px',
                                    height: '21px',
                                }}
                            />{' '}
                            Limpar filtros
                        </Button>
                    )}
                    <Button
                        className="m-2"
                        variant="light"
                        style={{ backgroundColor: '#EEEEEE' }}
                        onClick={() => setShowFilterSection(!showFilterSection)}
                    >
                        <FiFilter
                            fill="#bdbdbd"
                            style={{
                                color: '#bdbdbd',
                                width: '20px',
                                height: '20px',
                            }}
                        />
                    </Button>
                </ButtonGroup>
                <Button
                    className="float-right w-20 m-2"
                    style={{ width: '180px' }}
                    onClick={() => {
                        setShowAddInverterModal(true);
                    }}
                >
                    Novo inversor
                </Button>
            </Row>
            <InvertersFilterSection
                showFilterSection={showFilterSection}
                control={form.control}
            />
            <Row>
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh>ID</StyledTh>
                            <StyledTh>Código ERP inversor</StyledTh>
                            <StyledTh>Nome inversor</StyledTh>
                            <StyledTh>Marca inversor</StyledTh>
                            <StyledTh>Potência inversor</StyledTh>
                            <StyledTh>Código ERP conector</StyledTh>
                            <StyledTh>Quantidade de conectores</StyledTh>
                            <StyledTh>Código Stick Wi-Fi</StyledTh>
                            <StyledTh>Filiais</StyledTh>
                            <StyledTh>Ativo</StyledTh>
                            <StyledTh />
                        </tr>
                    </thead>
                    <tbody>
                        {inverters?.map((inverter: Inverter, index: number) => (
                            <tr key={inverter.id}>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={inverter.id.toString()}
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={inverter.inverterErpCode.toString()}
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={inverter.inverterName}
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={
                                            inverter.inverterBrand.name
                                        }
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={`${inverter.inverterPower.toString()}kW`}
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={
                                            inverter.connectorErpCode
                                        }
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={inverter.connectorsQuantity.toString()}
                                    />
                                </td>
                                <td>
                                    <Highlighter
                                        autoEscape
                                        highlightClassName="highlight-term"
                                        searchWords={[termTrimmed]}
                                        textToHighlight={
                                            inverter?.stickWifiCode?.length > 0
                                                ? inverter.stickWifiCode
                                                : '-'
                                        }
                                    />
                                </td>
                                <td>
                                    {inverter.branches
                                        .map((branch: Branch) => branch.name)
                                        .join(', ')}
                                </td>
                                <td>
                                    <Form.Check
                                        id={`${index.toString()}m`}
                                        checked={inverter.active}
                                        onChange={async () => {
                                            await handleActiveMutation.mutateAsync(
                                                inverter,
                                            );
                                        }}
                                        disabled={
                                            handleActiveMutation.isLoading
                                        }
                                    />
                                </td>
                                <td>
                                    <Dropdown key="left">
                                        <Dropdown.Toggle
                                            bsPrefix="nexen"
                                            className="mt-n3 mb-n3"
                                            as={Button}
                                            variant="text"
                                        >
                                            <ReactMoreIcon
                                                fill="#bdbdbd"
                                                width="10"
                                                height="20"
                                            />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setSelectedInverter(
                                                        inverter,
                                                    );

                                                    setShowAddInverterModal(
                                                        true,
                                                    );
                                                }}
                                            >
                                                Editar
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={() => {
                                                    setSelectedInverter(
                                                        inverter,
                                                    );

                                                    setShowRemoveInverterModal(
                                                        true,
                                                    );
                                                }}
                                            >
                                                Remover
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Row>
            <Row className="mx-4" ref={target}>
                <br />
                {showLoading && (
                    <Col md={12} className="text-center">
                        <Spinner animation="border" />
                    </Col>
                )}

                {inverters?.length === 0 && (
                    <Col md={12} className="text-center">
                        <strong style={{ color: '#adadad' }}>
                            Sem itens para carregar
                        </strong>
                    </Col>
                )}
            </Row>

            {showAddInverterModal && (
                <InverterModal
                    selectedInverter={selectedInverter}
                    show={showAddInverterModal}
                    refetch={invertersQuery.refetch}
                    onHide={() => {
                        setSelectedInverter(null);
                        setShowAddInverterModal(false);
                    }}
                />
            )}
            {showRemoveInverterModal && (
                <ConfirmationDialog
                    show={showRemoveInverterModal}
                    onHide={() => {
                        setShowRemoveInverterModal(false);
                        setSelectedInverter(null);
                    }}
                    onConfirm={async () => deleteMutation.mutateAsync()}
                    icon={NotificationIcon.Warning}
                    title="Excluir inversor"
                    text="Deseja excluir o inversor selecionado?"
                />
            )}
        </>
    );
}
