import RequestV2 from './request-v2';

export default class AuthHttpService {
    private static uri = 'auth';

    public static getUser() {
        return RequestV2.get(`${this.uri}/user`);
    }

    public static login(email: string, password: string) {
        return RequestV2.post(`${this.uri}/login`, {
            email,
            password,
        });
    }

    public static logout() {
        return RequestV2.post(`${this.uri}/logout`);
    }
}
