import RequestV2 from './request-v2';

export default class ReturnHttpService {
    private static uri = 'returns';

    public static index(params: {}) {
        return RequestV2.get(this.uri, params);
    }

    public static logistics(params: any) {
        return RequestV2.patch(
            `${this.uri}/${params.id}/approve/logistics`,
            params,
        );
    }

    public static financial(params: any) {
        return RequestV2.patch(
            `${this.uri}/${params.id}/approve/financial`,
            params,
        );
    }

    public static updateStatus(params: any) {
        return RequestV2.patch(`${this.uri}/${params.id}/approve`, params);
    }

    public static gerarRetorno(params: any) {
        return RequestV2.patch(`${this.uri}/${params.id}/generate`, params);
    }

    public static desmontarKits(params: any) {
        return RequestV2.patch(`${this.uri}/${params.id}/kit`, params);
    }

    public static removeOrder(params: number) {
        return RequestV2.del(`${this.uri}/${params}`);
    }
}
