import { ListAccessoriesParams } from '../../components/Configuration/OnlineStoreData/Accessories';
import Accessory from '../../shared/interfaces/accessory.interface';
import { ListInvertersParams } from './kit-v2.http';
import RequestV2 from './request-v2';

export default class ProductHttpService {
    private static uri = 'product';

    public static findAll(params: {}) {
        return RequestV2.get(`${this.uri}`, {
            ...params,
            withoutStorage: true,
        });
    }

    public static index(params: {}) {
        return RequestV2.get(this.uri, params);
    }

    public static getModules(data: any) {
        return RequestV2.get(`${this.uri}/modules`, data);
    }

    public static storeModule(data: any) {
        if ('id' in data && !!data.id) {
            return RequestV2.put(`${this.uri}/modules/${data.id}`, data);
        }
        return RequestV2.post(`${this.uri}/modules`, data);
    }

    public static deleteModule(id: number) {
        return RequestV2.del(`${this.uri}/modules/${id}`);
    }

    public static getRoofTypes(data: any) {
        return RequestV2.get(`${this.uri}/roof-types`, data);
    }

    public static storeRoofTypes(data: any) {
        if ('id' in data && !!data.id) {
            return RequestV2.put(`${this.uri}/roof-types/${data.id}`, data);
        }
        return RequestV2.post(`${this.uri}/roof-types`, data);
    }

    public static async deleteRoofType(id: number) {
        return RequestV2.del(`${this.uri}/roof-types/${id}`);
    }

    public static getInverters(params: ListInvertersParams) {
        return RequestV2.get(`${this.uri}/inverter`, params);
    }

    public static saveInverter(inverter: any) {
        if ('id' in inverter && !!inverter.id) {
            return RequestV2.put(
                `${this.uri}/inverter/${inverter.id}`,
                inverter,
            );
        }
        return RequestV2.post(`${this.uri}/inverter`, inverter);
    }

    public static async deleteInverter(id: number) {
        return RequestV2.del(`${this.uri}/inverter/${id}`);
    }

    public static getInverterBrands(params?: any) {
        return RequestV2.get(`${this.uri}/inverter/brand`, params);
    }

    public static async saveInverterBrand(inverterBrand: any) {
        if ('id' in inverterBrand && !!inverterBrand.id) {
            return RequestV2.put(
                `${this.uri}/inverter/brand/${inverterBrand.id}`,
                inverterBrand,
            );
        }
        return RequestV2.post(`${this.uri}/inverter/brand`, inverterBrand);
    }

    public static async deleteInverterBrand(id: number) {
        return RequestV2.del(`${this.uri}/inverter/brand/${id}`);
    }

    public static getAccessories(params?: ListAccessoriesParams) {
        return RequestV2.get(`${this.uri}/accessory`, params);
    }

    public static async saveAccessory(params: Accessory) {
        if ('id' in params && !!params.id) {
            return RequestV2.put(`${this.uri}/accessory/${params.id}`, params);
        }
        return RequestV2.post(`${this.uri}/accessory`, params);
    }

    public static async deleteAccessory(id: number) {
        return RequestV2.del(`${this.uri}/accessory/${id}`);
    }
}
