import RequestV2 from './request-v2';

export default class ReturnUsersHttpService {
    private static uriV2 = 'returns';

    public static index(params: {}) {
        return RequestV2.get(`${this.uriV2}/user`, params);
    }

    public static store(data: any) {
        return RequestV2.post(`${this.uriV2}/user/${data.user}`);
    }

    public static delete(id: number) {
        return RequestV2.del(`${this.uriV2}/user/${id}`);
    }
}
